import React, { useState } from "react"

import I18n from "i18n-js"

const languages = [
  {
    locale: "EN",
    name: "English"
  }, {
    locale: "DE",
    name: "Deutsch"
  }
]

const LanguageDropdown = () => {

  const [selected, setSelected] = useState(I18n.currentLocale().toUpperCase())
  const [isOpen, setOpen] = useState(false)

  const changeLocale = (desiredLocale: string) => {
    const url = new URL(location.href)
    url.searchParams.set("locale", desiredLocale.toLowerCase())
    location.href = url.href
  }

  return <div className="neo__clientNavigation__container__languageSelector">
    <button onClick={() => setOpen(!isOpen)}>
      <div className="neo__clientNavigation__icon--language" />
      <span>{selected || I18n.currentLocale().toUpperCase()}</span>
    </button>
    <ul className={"neo__clientNavigation__container__languageSelector__list" + (isOpen ? " neo__clientNavigation__container__languageSelector__list--open" : "")}>
      <button className="neo__clientNavigation__container__languageSelector__listBack" onClick={() => setOpen(!isOpen)}>
        <div className="neo__clientNavigation__icon--back" />
      </button>
      {languages.map((language, index) => {

        const classNames = [
          "neo__clientNavigation__container__languageSelector__listItem",
          language.locale === selected && "neo__clientNavigation__container__languageSelector__listItem--selected",
        ].join(" ")

        return <li
          className={classNames}
          key={`${language.locale}${index}`}
          onClick={() => changeLocale(language.locale)}
        >
          {selected === language.locale && <div className="neo__clientNavigation__icon--selected" />}
          {language.name}
        </li>
      })}
    </ul>
  </div>
}

export default LanguageDropdown
